.SBlock_s5oi5kh{background:#F5F5F5;margin-bottom:10px;max-width:100%;max-height:100%;border-radius:4px;position:relative;overflow:hidden;z-index:1;}@-webkit-keyframes ant-skeleton-loading-SBlock_s5oi5kh{0%{-webkit-transform:translate(-37.5%);-ms-transform:translate(-37.5%);transform:translate(-37.5%);}to{-webkit-transform:translate(37.5%);-ms-transform:translate(37.5%);transform:translate(37.5%);}}@keyframes ant-skeleton-loading-SBlock_s5oi5kh{0%{-webkit-transform:translate(-37.5%);-ms-transform:translate(-37.5%);transform:translate(-37.5%);}to{-webkit-transform:translate(37.5%);-ms-transform:translate(37.5%);transform:translate(37.5%);}}.SBlock_s5oi5kh:before{content:"";position:absolute;z-index:2;left:0;top:0;inset:0 -150%;background:linear-gradient( 90deg, rgba(238,238,238,0.2) 25%, rgba(129,129,129,0.24) 37%, rgba(206,206,206,0.2) 63% );-webkit-animation-name:ant-skeleton-loading-SBlock_s5oi5kh;animation-name:ant-skeleton-loading-SBlock_s5oi5kh;-webkit-animation-timing-function:ease-in-out;animation-timing-function:ease-in-out;-webkit-animation-direction:alternate;animation-direction:alternate;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;-webkit-animation-duration:1.4s;animation-duration:1.4s;}
.StyledSkeleton_s1vsylx2{position:absolute;left:0;top:0;bottom:0;right:0;width:100%;height:100%;background:#FFFFFF;z-index:6;padding:15px;margin:0;}
.SImage_splprr3.SBlock_s5oi5kh{width:100%;height:200px;grid-area:image;}
.SField_st4uuns.SBlock_s5oi5kh{height:24px;width:100%;grid-area:field;}
.SButton_sp3hmkk.SBlock_s5oi5kh{height:40px;border-radius:50px;width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-right:auto;margin-left:auto;}
.SButtonSmall_s1peu6v6.SButton_sp3hmkk.SBlock_s5oi5kh{height:30px;}
.SRow_s1xwf4ay{width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0 10px;}
.SContent_s1vpskhv{grid-area:content;}
.SProductCatalog_s1gfdi3r{position:relative;}[data-view=list] .SProductCatalog_s1gfdi3r,[data-view=checkout] .SProductCatalog_s1gfdi3r{display:grid;grid-template-areas:"image content";grid-template-columns:100px 8fr;gap:8px;}[data-view=list] .SProductCatalog_s1gfdi3r .SImage_splprr3,[data-view=checkout] .SProductCatalog_s1gfdi3r .SImage_splprr3{width:100px;height:100px;}[data-view=list] .SProductCatalog_s1gfdi3r .SContent_s1vpskhv > *,[data-view=checkout] .SProductCatalog_s1gfdi3r .SContent_s1vpskhv > *{display:none;}[data-view=list] .SProductCatalog_s1gfdi3r .SContent_s1vpskhv > *:nth-child(1),[data-view=checkout] .SProductCatalog_s1gfdi3r .SContent_s1vpskhv > *:nth-child(1){display:block;}[data-view=list] .SProductCatalog_s1gfdi3r .SContent_s1vpskhv > *:nth-child(2),[data-view=checkout] .SProductCatalog_s1gfdi3r .SContent_s1vpskhv > *:nth-child(2){display:block;}[data-view=list] .SProductCatalog_s1gfdi3r .SContent_s1vpskhv > *:nth-child(3),[data-view=checkout] .SProductCatalog_s1gfdi3r .SContent_s1vpskhv > *:nth-child(3){display:block;}@media (max-width:576px){[data-view=list] .SProductCatalog_s1gfdi3r,[data-view=checkout] .SProductCatalog_s1gfdi3r,.SProductCatalog_s1gfdi3r{display:grid;grid-template-areas:"content image";grid-template-columns:8fr 4fr;gap:8px;}[data-view=list] .SProductCatalog_s1gfdi3r .SImage_splprr3,[data-view=checkout] .SProductCatalog_s1gfdi3r .SImage_splprr3,.SProductCatalog_s1gfdi3r .SImage_splprr3{width:100px;height:100px;}}
.SProductDetail_sm8y6l0{position:relative;display:grid;grid-template-areas:"content image";grid-template-columns:8fr 4fr;gap:0 40px;}.SProductDetail_sm8y6l0 .SImage_splprr3{width:200px;height:200px;}
